import { FC, memo, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, List, Menu } from 'semantic-ui-react';
import './CommentsTab.scss';

import {
  editComment,
  postComment,
  subComments,
  unsubComments,
} from '../../../../DDPJS/DDPJS';
import { Comment } from './Comment';
import { MessageEnum, MsgLevelEnum } from '../../../MessageDialog/MessageIndex';
import { setErrorMessageToStore } from '../../../MessageDialog/MessageUtilities';
import * as VC from '../../../../util/versioncontrol';
import { FormattedMessage } from 'react-intl';
import { resetComment } from '../../../../store/comments/slice';
import { RichTextEditor } from '../../../Fields/RichTextEditor';
import {
  Comment as CommentInterface,
  GlobalState,
  Task,
} from '../../../../interfaces';
import { orderBy } from 'lodash';

interface IProps {
  task: Task;
}

export const CommentsTab: FC<IProps> = memo(({ task }) => {
  const [comment, setComment] = useState('');
  const [replyMode, setReplyMode] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editCommentID, setEditCommentID] = useState('-1');
  const contextRef = useRef<any>();
  const dispatch = useDispatch();
  const comments = useSelector((state: GlobalState) =>
    orderBy(state.comments, [(comment) => comment.PostedAt.$date], ['desc']),
  );

  useEffect(() => {
    const taskCommentsSubscriptionID = subComments(task.$ID);
    return () => {
      unsubComments(taskCommentsSubscriptionID);
      dispatch(resetComment());
    };
  }, []);

  const clickCancelButton = () => {
    setReplyMode(false);
    setEditMode(false);
    setEditCommentID('-1');
  };

  const clickPostButton = () => {
    const childComment = contextRef.current();
    if (editMode) {
      editComment(task.$ID, editCommentID, childComment);
    } else {
      if (childComment.trim().length > 0) {
        postComment(task.$ID, '-1', childComment);
      } else {
        setErrorMessageToStore(
          MsgLevelEnum.INFORMATION,
          MessageEnum.EMPTY_MESSAGE,
        );
      }
    }

    setReplyMode(false);
    setEditMode(false);
    setEditCommentID('-1');
    setComment('');
  };

  const clickReplyButton = () => {
    setReplyMode(true);
    setComment('');
  };

  const renderCommentsButtons = (length: number) => {
    if (!replyMode) {
      return (
        <Menu.Menu position="right">
          <div
            className="comment-button is-positive"
            onClick={clickReplyButton}
          >
            {length === 0 ? (
              <FormattedMessage id="ITEM_DETAILS.addComment" />
            ) : (
              <FormattedMessage id="GENERAL.reply" />
            )}
          </div>
        </Menu.Menu>
      );
    } else {
      return (
        <Menu.Menu position="right">
          <Button className="Comment-Button-Positive" onClick={clickPostButton}>
            <FormattedMessage id="GENERAL.post" />
          </Button>
          <Button
            className="Comment-Button-Negative"
            onClick={clickCancelButton}
          >
            <FormattedMessage id="GENERAL.cancel" />
          </Button>
        </Menu.Menu>
      );
    }
  };

  const handleChildClick = (childData: CommentInterface) => {
    setReplyMode(true);
    setEditMode(true);
    setComment(childData.Text);
    setEditCommentID(childData.id);
  };

  const editorCallbackFunction = (ref: any) => {
    if (ref !== null && ref.current !== undefined) {
      if (
        replyMode &&
        ref?.current !== null &&
        typeof ref?.current?.focusEditor === 'function'
      ) {
        ref.current.focusEditor();
      }
    }
  };

  const versionControlFileInfo = (tokenUrl: string) => {
    const urlParts = tokenUrl.split('/');
    return VC.mapFileIDToInfo(task, parseInt(urlParts[urlParts.length - 1]));
  };

  const fieldData = {
    id: task.$ID,
    fieldID: 'Comment', // fake id being used for comments
    readOnly: false,
    fieldValue: comment,
  };

  return (
    <div id="comments_link" className="js-comments-link comments-tab">
      <div className="commentcontrol-title">
        <div className="commentcontrol-title-text">
          <FormattedMessage id="ITEM_DETAILS.comments" />
        </div>
        {renderCommentsButtons(comments.length)}
      </div>
      {replyMode && (
        <RichTextEditor
          {...fieldData}
          task={task}
          onRef={editorCallbackFunction}
          postComment={clickPostButton}
          getContextRef={contextRef}
          disableOnBlur
        />
      )}
      <div className="comments-list">
        <List selection verticalAlign="middle">
          {comments.length === 0 && !replyMode && (
            <div className="comment-emptystate">
              <FormattedMessage id="ITEM_DETAILS.noCommentsPostedYet" />
            </div>
          )}
          {comments.map((childData, childIndex) => (
            <Comment
              key={childIndex}
              comment={childData}
              onEditComment={() => handleChildClick(childData)}
              versionControlFileInfo={versionControlFileInfo}
            />
          ))}
        </List>
      </div>
    </div>
  );
});

export default CommentsTab;
