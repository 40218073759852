import React, { FC, memo, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Loader } from 'semantic-ui-react';
import Result from './Result';
import { ChartResultSet } from '../../../../interfaces';

interface IProps {
  id: number;
  name: string;
  mainTheme: string;
  theme: string;
  chartResultSet?: ChartResultSet;
}

export const ResultSetChart: FC<IProps> = memo(
  ({ chartResultSet, name, theme, mainTheme }) => {
    const [sizeInfo, setSizeInfo] = useState({
      width: 0,
      height: 0,
    });
    const ref = useRef<HTMLDivElement>(null);

    const intl = useIntl();

    const handleResize = () => {
      if (ref.current !== null && ref.current.getBoundingClientRect().width)
        setSizeInfo({
          width: ref.current.getBoundingClientRect().width - 32,
          height: ref.current.getBoundingClientRect().width / 2,
        });
    };

    useEffect(() => {
      handleResize();
      //eslint-disable-next-line
    }, [ref.current]);

    useEffect(() => {
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
      <div className="charttile" ref={ref}>
        <div className="name">
          {name ||
            `(${intl.formatMessage({
              id: 'CHARTS.untitledChart',
            })})`}
        </div>
        {chartResultSet ? (
          <Result
            chartResultSet={chartResultSet}
            theme={theme}
            mainTheme={mainTheme}
            maxHeight={sizeInfo.height}
            maxWidth={sizeInfo.width}
          />
        ) : (
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              minHeight: sizeInfo.height,
              maxHeight: sizeInfo.height,
              justifyContent: 'center',
            }}
          >
            <Loader active inline />
          </div>
        )}
      </div>
    );
  },
);

export default ResultSetChart;
