import { FC, memo } from 'react';
import { SingleLineFieldBase, Task, TaskFieldProps } from '../../../interfaces';
import { DescriptionField } from '../../Fields/DescriptionField';
import { IntegrationField } from '../../Fields/IntegrationField';
import { DueDateField } from '../../Fields/DueDateField';
import { EnumSingleSelect } from '../../Fields/EnumSingleSelect';
import { PrioritySingleSelect } from '../../Fields/PrioritySingleSelect';
import { WorkflowStatusSingleSelect } from '../../Fields/WorkflowStatusSingleSelect';
import { StatusSingleSelect } from '../../Fields/StatusSingleSelect';
import { EnumField } from '../../Fields/EnumField';
import { TextField } from '../../Fields/TextField';
import { DateTime } from '../../Labels/DateTime';
import { Number } from '../../Fields/Number';
import { HyperlinkField } from '../../Fields/HyperlinkField';
import { ResourceAllocationsField } from '../../Fields/ResourceAllocationsField';
import { ResourceField } from '../../Fields/ResourceField';
import { stringConstants } from '../../../constants/strings.constants';
import { TimeSpentListField } from '../../Fields/TimeSpentListField';
import { getProjectInfo } from '../../../util/project/getProjectInfo';
import { fieldIsEnabled } from '../../../util/task/fields/fieldIsEnabled';
import {
  taskHasProperty,
  taskHasPriority,
  taskHasWorkflow,
  taskIsInSprint,
} from '../../../util/task/propertyHelpers';

interface IProps extends TaskFieldProps {
  databaseGUIDHash: string;
  showCoverImages: boolean;
  integrationData: any;
  customPriorityFields: SingleLineFieldBase[];
}

export const TaskField: FC<IProps> = memo(
  ({
    task,
    enabledField,
    databaseGUIDHash,
    showCoverImages,
    integrationData,
    customPriorityFields,
  }) => {
    const taskHasCustomPriority = (
      task: Task,
      customPriorityFields: SingleLineFieldBase[],
    ): string | undefined => {
      return customPriorityFields.find(
        (field: SingleLineFieldBase) => task.fields[field.id] !== undefined,
      )?.id;
    };

    if (enabledField.id === 'Description')
      return (
        <DescriptionField
          databaseGUIDHash={databaseGUIDHash}
          showCoverImages={showCoverImages}
          task={task}
          enabledField={enabledField}
        />
      );
    else if (enabledField.id === 'Integration')
      return (
        <IntegrationField
          integrationData={integrationData}
          task={task}
          databaseGUIDHash={databaseGUIDHash}
        />
      );
    else if (enabledField.id === 'DueDate') {
      return <DueDateField task={task} enabledField={enabledField} />;
    } else if (enabledField.id === 'Priority') {
      const enabledBugs = fieldIsEnabled('BugPriority', task);
      return (
        <>
          {taskHasCustomPriority(task, customPriorityFields) ? (
            <EnumSingleSelect
              task={task}
              fieldID={taskHasCustomPriority(task, customPriorityFields) ?? ''}
              isListView
              isCustomPriority
            />
          ) : (
            taskHasPriority(task) && (
              <PrioritySingleSelect
                task={task}
                fieldID={
                  enabledBugs && enabledBugs[0]
                    ? 'BugPriority'
                    : 'SprintPriority'
                }
                isListView
              />
            )
          )}
        </>
      );
    } else if (enabledField.id === 'Status') {
      if (!taskHasProperty(task, 'Status')) {
        return <div key={enabledField.id} className="field is-disabled" />;
      } else if (taskHasWorkflow(task)) {
        const projectInfo = getProjectInfo(task.$ProjectID);
        if (projectInfo[1] === undefined) return null;

        return (
          <WorkflowStatusSingleSelect
            task={task}
            fieldID={'Status'}
            isListView={true}
          />
        );
      }

      return <StatusSingleSelect task={task} isListView={true} />;
    } else if (enabledField.type === 'MultiEnum' && enabledField.values)
      return <EnumField task={task} enabledField={enabledField} />;
    else if (enabledField.type === 'Enum' && enabledField.values)
      return <EnumField task={task} enabledField={enabledField} />;
    else if (
      enabledField.type === 'Text' ||
      enabledField.type === 'String' ||
      (enabledField.type === 'Integer' && enabledField.readOnly) ||
      (enabledField.type === 'Float' && enabledField.readOnly)
    )
      return <TextField task={task} enabledField={enabledField} />;
    else if (enabledField.type === 'DateTime')
      return <DateTime task={task} enabledField={enabledField} />;
    else if (enabledField.type === 'DateTimeTime')
      return <DateTime task={task} enabledField={enabledField} showTime />;
    else if (enabledField.type === 'Integer' || enabledField.type === 'Float') {
      return <Number task={task} fieldID={enabledField.id} isListView={true} />;
    } else if (enabledField.type === 'Hyperlink')
      return <HyperlinkField task={task} enabledField={enabledField} />;
    else if (enabledField.type === 'ResourceAllocations')
      return (
        <ResourceAllocationsField task={task} enabledField={enabledField} />
      );
    else if (enabledField.type === 'Resources')
      return <ResourceField task={task} enabledField={enabledField} />;
    else if (enabledField.type === 'Hours') {
      if (enabledField.id === 'WorkRemaining') {
        const projectData = getProjectInfo(task.$ProjectID);
        const projectType = projectData[0] ? projectData[0].Type : null;
        if (projectType === 'Planning' && !taskIsInSprint(task))
          return <div key={enabledField.id} className="is-disabled" />;
      }

      if (enabledField.name === stringConstants.TIME_SPENT_COLUMN) {
        return <TimeSpentListField task={task} fieldID={enabledField.id} />;
      }

      return <Number task={task} fieldID={enabledField.id} isListView={true} />;
    } else {
      return (
        <div
          key={enabledField.id}
          className="field is-disabled is-notimplemented"
        />
      );
    }
  },
);

export default TaskField;
