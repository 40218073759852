import { FC, memo, useEffect, useState } from 'react';
import { Segment } from 'semantic-ui-react';
import Datetime from 'react-datetime';
import moment from 'moment';
import 'react-datetime/css/react-datetime.css';
import { setField } from '../../../DDPJS/DDPJS';
import {
  createEJSONDateObject,
  isValidEJSONDateObject,
  getEJSONDateValue,
} from '../../../util/date-time/EjsonDateFunctions';
import {
  formatYYYYMMDD,
  formatTime24Hour,
  getLocaleDateString,
} from '../../../util/date-time/dateTimeFormat';
import { ItemFieldWrapper } from '../../ItemDetailsPage/ItemFieldWrapper';
import { isValidObject } from '../../../util/validationFunctions';
import { Task } from '../../../interfaces';
import { getTaskProperty } from '../../../util/task/propertyHelpers';
import {
  checkIfFieldIsReadOnly,
  getFieldDefinition,
  isWorkflowRequiredField,
} from '../helpers';

import styles from './DateTime.module.scss';

interface IProps {
  task: Task;
  fieldID: string;
}

export const DateTime: FC<IProps> = memo(({ task, fieldID }) => {
  const [value, setValue] = useState(getEJSONDateValue(task.fields, fieldID));
  const [redraw, setRedraw] = useState(false);

  useEffect(() => {
    if (isValidEJSONDateObject(getTaskProperty(task, fieldID))) {
      setValue(getEJSONDateValue(task.fields, fieldID));
    } else if (!isValidEJSONDateObject(getTaskProperty(task, fieldID))) {
      setValue(0);
    }
  }, getTaskProperty(task, fieldID));

  const convertValueToTimestamp = (moment: any) => {
    if (timeFormat()) return moment.utc().toDate().getTime();

    const dateValue = moment.toDate();
    return Date.UTC(
      dateValue.getFullYear(),
      dateValue.getMonth(),
      dateValue.getDate(),
    );
  };

  const onChange = (moment: any) => {
    try {
      if (checkIfFieldIsReadOnly(fieldID, task)) {
        return;
      }

      if (
        !isValidObject(moment) ||
        typeof moment === 'string' ||
        !moment?.isValid()
      ) {
        return;
      }

      setValue(convertValueToTimestamp(moment));
    } catch (error) {
      // TODO: find a better way to log errors
      console.error(error);
    }
  };

  const onClose = () => {
    if (checkIfFieldIsReadOnly(fieldID, task)) {
      return;
    }

    setRedraw(true);
    setField(
      getFieldDefinition(fieldID, task).id,
      task.$ID,
      createEJSONDateObject(dateValue),
    );
  };

  const timeFormat = () => {
    return getFieldDefinition(fieldID, task).Type === 'DateTimeTime';
  };

  if (!isValidObject(getFieldDefinition(fieldID, task))) {
    return null;
  }

  const dateValue = new Date(value);
  const myMoment = moment(dateValue);
  const isValid = myMoment.isValid() && value > 0;
  if (!isValid && value === 0 && redraw) {
    setRedraw(false);
  }

  if (checkIfFieldIsReadOnly(fieldID, task)) {
    return (
      <ItemFieldWrapper
        fieldName={getFieldDefinition(fieldID, task).DisplayName}
        isRequiredField={isWorkflowRequiredField(fieldID, task)}
      >
        <div className={styles.readOnlyBackground}>
          <span className={styles.readOnlyDate}>
            {isValid
              ? `${formatYYYYMMDD(dateValue)} ${
                  timeFormat() ? ' ' + formatTime24Hour(dateValue) : ''
                }`
              : ''}
          </span>
        </div>
      </ItemFieldWrapper>
    );
  } else {
    return (
      <ItemFieldWrapper
        fieldName={getFieldDefinition(fieldID, task).DisplayName}
      >
        <div>
          <Segment className={styles.fieldSegment}>
            {isValid ? (
              <Datetime
                timeFormat={timeFormat()}
                dateFormat={getLocaleDateString()}
                locale={navigator.language}
                closeOnSelect={true}
                value={dateValue}
                onChange={onChange}
                onClose={onClose}
              />
            ) : (
              !redraw && (
                <Datetime
                  timeFormat={timeFormat()}
                  dateFormat={getLocaleDateString()}
                  locale={navigator.language}
                  closeOnSelect={true}
                  onChange={onChange}
                  onClose={onClose}
                />
              )
            )}
          </Segment>
        </div>
      </ItemFieldWrapper>
    );
  }
});

export default DateTime;
