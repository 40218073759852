import { FC, useCallback } from 'react';
import { Task } from '../../../interfaces';
import { BuiltInSingleSelect } from '../BuiltInSingleSelect';
import {
  colorEnumToText,
  colorToRgbCodeInDarkMode,
  colorToRgbCodeInLightMode,
} from '../../../mappers';
import { Colors, Theme } from '../../../enums';
import { Box } from '../../Icons';
import { useIntl } from 'react-intl';

interface IProps {
  task: Task;
  fieldId: string;
  theme: string;
}

export const ColorSingleSelectField: FC<IProps> = ({
  task,
  fieldId,
  theme,
}) => {
  const intl = useIntl();

  const getText = useCallback(
    (value: string) => {
      try {
        return intl.formatMessage({
          id: `ITEM_DETAILS.color.${colorEnumToText.get(+value)}`,
          defaultMessage: colorEnumToText.get(+value),
        });
      } catch (error) {
        return '';
      }
    },
    [intl],
  );

  const getIcon = useCallback(
    (color: number) => {
      if (color > 1) {
        return (
          <Box
            color={`rgba(${
              theme === Theme.LIGHT
                ? colorToRgbCodeInLightMode.get(color)
                : colorToRgbCodeInDarkMode.get(color)
            })`}
          />
        );
      }
    },
    [theme],
  );

  const getOptionIcon = useCallback((color: number) => {
    if (color > 1) {
      return <Box color={`rgba(${colorToRgbCodeInLightMode.get(color)})`} />;
    }
  }, []);

  const getDropdownOptions = (value: string | number) => {
    const options = [];

    if (value != 0) {
      options.push({
        value: value,
        selected: !!value,
        image: getOptionIcon(+value),
        text: intl.formatMessage({
          id: `ITEM_DETAILS.color.${colorEnumToText.get(+value)}`,
          defaultMessage: colorEnumToText.get(+value),
        }),
      });
    }

    Object.values(Colors)
      .filter((color) => !isNaN(+color) && +color !== 0)
      .forEach((color) => {
        if (+color !== +value) {
          options.push({
            value: +color,
            selected: +color === +value,
            image: getOptionIcon(+color),
            text: intl.formatMessage({
              id: `ITEM_DETAILS.color.${colorEnumToText.get(+color)}`,
              defaultMessage: colorEnumToText.get(+color),
            }),
          });
        }
      });

    return options;
  };

  return (
    <BuiltInSingleSelect
      task={task}
      fieldID={fieldId}
      getText={getText}
      getOptions={getDropdownOptions}
      getIcon={(value: string | number) => getIcon(+value)}
    />
  );
};

export default ColorSingleSelectField;
