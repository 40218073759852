import { FC, useCallback, useState, MouseEvent } from 'react';
import * as VC from '../../../util/versioncontrol';
import { Image, Modal } from 'semantic-ui-react';
import { ImgNoComment } from '../../../images/images';
import sharedStyles from '../../../shared.module.scss';
import { TaskFieldProps } from '../../../interfaces';
import styles from './DescriptionField.module.scss';
import { Properties } from 'csstype';
import {
  colorToRgbCodeInDarkMode,
  colorToRgbCodeInLightMode,
} from '../../../mappers';
import { Theme } from '../../../enums';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { isValidObject } from '../../../util/validationFunctions';
import { getProjectInfo } from '../../../util/project/getProjectInfo';
import { SprintIconWithTooltip } from '../../Labels/SprintIconWithTooltip';
import { taskIsInSprint } from '../../../util/task/propertyHelpers';

interface IProps extends TaskFieldProps {
  databaseGUIDHash: string;
  showCoverImages: boolean;
}

export const DescriptionField: FC<IProps> = ({
  databaseGUIDHash,
  showCoverImages,
  task,
}: IProps) => {
  let description = task.fields.Description.trim();
  const intl = useIntl();

  if (!description)
    description = `(${intl.formatMessage({ id: 'GENERAL.noName' })})`;

  const [keyImagePreviewOpen, setKeyImagePreviewOpen] = useState(false);

  const projectData = getProjectInfo(task.$ProjectID);
  const projectName = projectData[0] ? projectData[0].Name : '';
  const subProjectPath = task.fields.SubProjectPath || '';
  const mainImageFileInfo = VC.mapMainImageToInfo(task);
  const history = useHistory();
  const getThemeName = (): string => {
    return localStorage.getItem('theme') || Theme.DARK;
  };

  const backGroundColor: Properties = {
    backgroundColor: `rgb(${
      getThemeName() === Theme.LIGHT
        ? colorToRgbCodeInLightMode.get(task.fields.Color)
        : colorToRgbCodeInDarkMode.get(task.fields.Color)
    })`,
  };

  const closeImageFileInfoModal = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      setKeyImagePreviewOpen(false);
    },
    [],
  );

  const openImageFileInfoModal = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setKeyImagePreviewOpen(true);
    },
    [],
  );

  const navigateToTaskDetails = () => {
    history.push(`/task/${databaseGUIDHash}/${task.$ID}`);
  };

  return (
    <div
      className="field is-disabled description-cell"
      onClick={navigateToTaskDetails}
    >
      {showCoverImages && (
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            height: 100,
            minHeight: 100,
            width: 124,
            minWidth: 124,
            paddingRight: 16,
          }}
        >
          {isValidObject(task.fields.Color) && task.fields.Color !== 1 && (
            <div
              className={styles.taskColorWithImage}
              style={backGroundColor}
            />
          )}
          {mainImageFileInfo ? (
            <Image
              inline
              title={intl.formatMessage({
                id: 'GENERAL.clickToViewFullSize',
              })}
              style={{ cursor: 'pointer', maxHeight: '100%' }}
              src={mainImageFileInfo.url}
              onClick={openImageFileInfoModal}
            />
          ) : (
            <Image inline src={ImgNoComment} />
          )}
        </div>
      )}
      <div className={sharedStyles.displayFlex}>
        {!showCoverImages &&
          isValidObject(task.fields.Color) &&
          task.fields.Color !== 1 && (
            <div className={styles.taskColor} style={backGroundColor} />
          )}
        <div className="description">
          <div className={sharedStyles.displayFlex}>
            {!showCoverImages &&
              isValidObject(task.fields.Color) &&
              task.fields.Color !== 1 && (
                <div className={styles.taskColor} style={backGroundColor} />
              )}
            <div className="description">
              <div className="project">
                {taskIsInSprint(task) && (
                  <div className={styles.sprintIcon}>
                    <SprintIconWithTooltip task={task} />
                  </div>
                )}
                {`${projectName} ${subProjectPath && ` / ${subProjectPath}`}`}
              </div>
              <span className="taskname js-task-name">{description}</span>
            </div>
          </div>
        </div>
        {mainImageFileInfo && (
          <Modal
            className="lightbox"
            closeIcon
            open={keyImagePreviewOpen}
            onClose={closeImageFileInfoModal}
          >
            <Modal.Content image>
              <Image
                centered
                src={mainImageFileInfo.url}
                onClick={closeImageFileInfoModal}
              />
            </Modal.Content>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default DescriptionField;
