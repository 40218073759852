import React, { FC, memo } from 'react';

interface IProps {
  block: any;
  contentState: any;
}

export const AtomicBlock: FC<IProps> = memo(({ block, contentState }) => {
  const entity = contentState.getEntity(block.getEntityAt(0));
  const { width } = entity.getData();
  const type = entity.getType();

  return type === 'Image' ? (
    <img
      src={entity.data.src}
      alt=""
      style={{ maxWidth: width, width: '100%' }}
    />
  ) : (
    <></>
  );
});

export default AtomicBlock;
