import { Component } from 'react';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import stacked100 from 'chartjs-plugin-stacked100';
import 'chartjs-plugin-annotation';
import _ from 'underscore';
import moment from 'moment';

import './Result.scss';
import ItemView from '../../ItemView';
import Gauge from '../../Gauge';
import { FormattedMessage, injectIntl } from 'react-intl';
import { getChartLegend } from '../../../helpers/getChartLegend';
import { getTooltipLabel } from '../../../helpers/getTooltipLabel';
import { chartDataLabelsFormatter } from '../../../helpers/chartDataLabelsFormatter';
import { Graph } from '../../Graph/Graph';
import { Graph as GraphEnum, Locale } from '../../../../../enums';
import camelCase from 'lodash/camelCase';
import { getLocale } from '../../../../../util/localStorage';
import { convertDateToDashboardTranslatedString } from '../../../../../util/date-time/dateTimeFormat';

export class Result extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chartRef: null,
      customLegend: null,
      windowWidth: null,
    };

    this.updateLegend = this.updateLegend.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      windowWidth: window.innerWidth,
    });
  }

  updateLegend() {
    if (!this.chartRef || !this.chartRef.chartInstance) return;

    this.setState({
      customLegend: this.chartRef.chartInstance.generateLegend(),
    });
  }

  componentDidMount() {
    this.updateWindowDimensions();
    this.updateLegend();
    window.addEventListener('resize', this.updateWindowDimensions, false);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions, false);
  }

  componentDidUpdate(prevProps) {
    if (this.props === prevProps) return;

    this.updateLegend();
  }

  render() {
    const { chartResultSet, intl, maxHeight, mainTheme, maxWidth } = this.props;
    const chartStyling = {
      display: 'flex',
      flexFlow: 'row nowrap',
      maxHeight,
      minHeight: maxHeight,
    };

    if (ChartDataLabels) {
      // TODO: Prevent warning in a better way
    }
    if (stacked100) {
      // TODO: Prevent warning in a better way
    }

    if (
      chartResultSet.error ||
      (chartResultSet.datasets && chartResultSet.datasets.length === 0)
    )
      return (
        <div className="chart" style={chartStyling}>
          {chartResultSet.error ||
            intl.formatMessage({ id: 'GENERAL.noDataHere' })}
        </div>
      );
    else if (
      chartResultSet.type === GraphEnum.GAUGE &&
      chartResultSet.upperBound === chartResultSet.lowerBound
    )
      return (
        <div className="chart" style={chartStyling}>
          <FormattedMessage id="CHARTS.setBoundsToControlColor" />
        </div>
      );

    const chartType = chartResultSet.type;
    const isDarkTheme = mainTheme === 'dark';

    if (chartType === GraphEnum.ITEM_VIEW)
      return <ItemView data={chartResultSet} maxHeight={maxHeight} />;
    else if (chartType === GraphEnum.GAUGE)
      return <Gauge data={chartResultSet} maxHeight={maxHeight} />;

    if (!chartResultSet.datasets) return null;

    const fontColor = isDarkTheme ? '#ffffff' : '#262626';
    const lineColor = isDarkTheme ? '#45474d' : '#ebebeb';
    const backgroundColor = isDarkTheme ? 'rgb(45,46,50)' : 'white';

    const burndownKey = `__IDEAL_BURNDOWN_HIDDEN_KEY_${new Date().getTime()}`;

    let options = {
      maintainAspectRatio: false,
      responsive: true,
      plugins: {},
      legendCallback: () => getChartLegend(chartResultSet, burndownKey, intl),
      legend: {
        display: false,
      },
      animation: {
        duration: 0,
      },
      responsiveAnimationDuration: 0,
    };

    const addScalesOption = (scale, key, value) => {
      if (!options.scales) options.scales = {};
      if (!options.scales[scale]) options.scales[scale] = [{}];
      if (typeof value === 'object')
        options.scales[scale][0][key] = _.extend(
          options.scales[scale][0][key] || {},
          value,
        );
      else options.scales[scale][0][key] = value;
    };

    if (chartType !== GraphEnum.PIE) {
      addScalesOption('xAxes', 'ticks', {
        suggestedMin: 0,
        fontColor: fontColor,
      });
      addScalesOption('yAxes', 'ticks', {
        suggestedMin: 0,
        fontColor: fontColor,
      });

      addScalesOption('xAxes', 'gridLines', {
        color: lineColor,
        lineWidth: 0,
        zeroLineColor: lineColor,
      });

      addScalesOption('yAxes', 'gridLines', {
        color: lineColor,
        zeroLineColor: lineColor,
      });

      if (chartType === GraphEnum.SCATTER) {
        if (chartResultSet.axisLabels) {
          addScalesOption('xAxes', 'scaleLabel', {
            display: true,
            labelString:
              intl.formatMessage({
                id: `CHARTS.${camelCase(chartResultSet.axisLabels[0])}`,
                defaultMessage: chartResultSet.axisLabels[0],
              }) || '',
          });
          addScalesOption('yAxes', 'scaleLabel', {
            display: true,
            labelString:
              intl.formatMessage({
                id: `CHARTS.${camelCase(chartResultSet.axisLabels[1])}`,
                defaultMessage: chartResultSet.axisLabels[1],
              }) || '',
          });
        }
      } else {
        if (chartType === GraphEnum.BAR) {
          if (
            chartResultSet.grouping === 'stack' ||
            chartResultSet.grouping === 'stackPercentage'
          ) {
            addScalesOption('xAxes', 'stacked', true);
            addScalesOption('yAxes', 'stacked', true);
          }
        } else if (
          chartType === GraphEnum.AREA &&
          chartResultSet.grouping === 'stack'
        ) {
          addScalesOption('yAxes', 'stacked', true);
        }

        if (chartResultSet.grouping === 'stackPercentage') {
          options.plugins.stacked100 = {
            enable: true,
            replaceTooltipLabel: false,
          };
        } else {
          addScalesOption(
            chartResultSet.orientation === 'horizontal' ? 'xAxes' : 'yAxes',
            'ticks',
            {
              fontColor: fontColor,
              min: chartResultSet.yMinValue,
              max: chartResultSet.yMaxValue,
              suggestedMin: 0,
            },
          );
        }
      }
    } else if (chartType === GraphEnum.PIE) {
      options.tooltips = {
        callbacks: {
          label: (item, data) => {
            const label = data.datasets[item.datasetIndex].label[item.index];

            return `${
              label.$date
                ? convertDateToDashboardTranslatedString(label.$date, intl)
                : label
            }: ${data.datasets[item.datasetIndex].data[item.index]}`;
          },
        },
      };
    }

    if (chartType === GraphEnum.LINE || chartType === GraphEnum.AREA) {
      addScalesOption('xAxes', 'type', 'time');
      addScalesOption('xAxes', 'time', {
        unit: 'day',
        displayFormats: {
          day:
            getLocale() === Locale.ENGLISH_UK ||
            getLocale() === Locale.ENGLISH_US
              ? 'MMM D'
              : `M${intl.formatMessage({
                  id: 'GENERAL.month',
                })}D${intl.formatMessage({ id: 'GENERAL.day' })}`,
        },
      });
      addScalesOption('xAxes', 'ticks', {
        autoSkip: true,
        autoSkipPadding: 8,
        maxRotation: 0,
      });
    }

    options.tooltips = {
      displayColors: chartType === 'bar',
      callbacks: {
        title: () => {
          return null;
        },
        label: (item, data) =>
          getTooltipLabel(item, data, burndownKey, chartResultSet.type, intl),
      },
    };

    if (chartResultSet.forecastingIndex > 0) {
      options.annotation = {
        drawTime: 'afterDatasetsDraw',
        annotations: [
          {
            type: 'line',
            mode: 'vertical',
            scaleID: 'x-axis-0',
            borderColor: 'rgb(135, 235, 135)',
            value: moment().startOf('day').toDate().getTime(),
          },
        ],
      };
    }

    options.plugins.datalabels = {
      clamp: true,
      display: () => {
        if (chartType === GraphEnum.PIE) return 'auto';
        else if (
          chartType === GraphEnum.AREA ||
          chartType === GraphEnum.LINE ||
          chartType === GraphEnum.SCATTER
        )
          return false;
        return true;
      },
      color: (context) => {
        if (context.chart.data.type === GraphEnum.BAR) {
          if (context.dataset.data[context.dataIndex] === 0) return fontColor;
          return context.dataset.labelColor;
        } else if (context.chart.data.type === GraphEnum.PIE) {
          return context.dataset.labelColor[context.dataIndex];
        }

        return fontColor;
      },
      formatter: chartDataLabelsFormatter,
    };

    return (
      <div className="chart" style={chartStyling}>
        <div className="chart-wrapper" style={{ flex: 3, maxWidth: maxWidth }}>
          <Graph
            chartResultSet={chartResultSet}
            options={options}
            backgroundColor={backgroundColor}
            burndownKey={burndownKey}
            updateChartRef={this.updateChartRef}
          />
        </div>
        {this.state.customLegend && (
          <div
            style={{
              display: 'flex',
              flexFlow: 'column nowrap',
              justifyContent: 'center',
              maxWidth: '20%',
            }}
          >
            {this.state.customLegend}
          </div>
        )}
      </div>
    );
  }

  updateChartRef = (element) => (this.chartRef = element);
}

export default injectIntl(Result);
