import { FC, FormEvent, useEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Message, Grid } from 'semantic-ui-react';
import { loginHansoft } from '../../DDPJS/DDPJS';

import { userActions } from '../../actions/user.actions';
import styles from './Login.module.scss';
import { ImgLoginLogo } from '../../images/images';
import MessageDialog from '../MessageDialog/MessageDialog';
import { triggerMessageDialog } from '../MessageDialog/MessageUtilities';
import { history } from '../../util/history';
import { Copyright } from './Copyright';
import * as VC from '../../util/versioncontrol';
import { FormattedMessage, useIntl } from 'react-intl';
import { GlobalState } from '../../interfaces';
import { getBrowserName } from '../../util/getBrowserName';

export const Login: FC = () => {
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const location = useLocation<any>();

  const dispatch = useDispatch();
  const intl = useIntl();

  const error = useSelector((state: GlobalState) => state.error);
  const loginError = useSelector((state: GlobalState) => state.loginError);
  const serviceVersion = useSelector(
    (state: GlobalState) => state.appState.serviceVersion,
  );

  useEffect(() => {
    dispatch(userActions.logout());
    document.addEventListener('keypress', handleKeyPress);

    VC.getServiceInfo();
    triggerMessageDialog(error.isError);
    return () => {
      document.removeEventListener('keypress', handleKeyPress);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (error.isError) {
      triggerMessageDialog(error.isError);
    }
  }, [error.isError]);

  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    if (name === 'username') {
      setUsername(value);
    } else {
      setPassword(value);
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    doLogin();
  };

  const doLogin = () => {
    const from = location.state ? location.state.from : null;

    let integrationData: any;
    if (!from) {
      const data = sessionStorage.getItem('integration');
      if (data) {
        integrationData = JSON.parse(data);
      }
    }

    const source =
      integrationData && integrationData.data.source
        ? integrationData.data.source
        : getBrowserName();
    loginHansoft(username, password, source, () => {
      const search = (from && from.search) || '';
      if (from && from.pathname !== '/') {
        history.push(from.pathname + search);
      } else if (
        integrationData &&
        integrationData.data.redirectPath &&
        integrationData.data.redirectPath.length !== 0
      ) {
        history.push(integrationData.data.redirectPath);
      } else {
        history.push('/' + search);
      }
    });
  };

  const handleKeyPress = (e: KeyboardEvent) => {
    // "Enter" key
    if (e.keyCode === 13) {
      doLogin();
    }
  };

  if (localStorage.getItem('user')) {
    return <Redirect to={'/'} />;
  }

  let messageComponent;
  if (loginError.isError) {
    messageComponent = (
      <Message
        id="js-login-message"
        negative
        className={`${styles.LoginMessageStyle}`}
      >
        <Message.Content>
          <span className={styles.Label}>Login Error:&nbsp;</span>
          {loginError.message}
        </Message.Content>
      </Message>
    );
  }

  const isLoginDisabled = () => username.length === 0 || password.length === 0;

  return (
    <div>
      <MessageDialog />

      <div className={styles.LoginPageStyle}>
        <Grid textAlign="center">
          <Grid.Row />
          <Grid.Row />
          <Grid.Row>
            <img src={ImgLoginLogo} alt={'logo'} />
          </Grid.Row>
          {messageComponent}
          <Grid.Row>
            <div className={styles.Center}>
              <Form name="form" onSubmit={handleSubmit}>
                <Form.Field className={styles.LoginForm}>
                  <input
                    id="js-login-username"
                    type="text"
                    name="username"
                    placeholder={intl.formatMessage({
                      id: 'LOGIN.username',
                    })}
                    value={username}
                    onChange={handleChange}
                  />
                </Form.Field>
                <Form.Field
                  className={`${styles.LoginForm} ${styles.PasswordField}`}
                >
                  <input
                    id="js-login-password"
                    type="password"
                    name="password"
                    placeholder={intl.formatMessage({
                      id: 'LOGIN.password',
                    })}
                    value={password}
                    onChange={handleChange}
                  />
                </Form.Field>
                <Button
                  id="js-login"
                  primary
                  type="submit"
                  className={`${styles.LoginButton}`}
                  disabled={isLoginDisabled()}
                >
                  <FormattedMessage id="LOGIN.login" />
                </Button>
              </Form>
            </div>
          </Grid.Row>
        </Grid>
        <Copyright serviceVersion={serviceVersion} />
      </div>
    </div>
  );
};

export default Login;
