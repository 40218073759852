import React, { FC, memo } from 'react';
import { LinkedToItem } from '../../../../interfaces';
import sharedStyles from '../../../../shared.module.scss';
import styles from './Item.module.scss';
import {
  ImgBlocked,
  ImgCompleted,
  ImgInProgress,
  ImgNotDone,
  ImgToBeDeleted,
  ImgUserGreen,
  ImgUserGroup,
} from '../../../../images/images';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

interface IProps {
  item: LinkedToItem;
}

// eslint-disable-next-line react/display-name
export const Item: FC<IProps> = memo(({ item }) => {
  const statusIcons = [
    ImgNotDone,
    ImgInProgress,
    ImgCompleted,
    ImgBlocked,
    ImgToBeDeleted,
  ];

  const projectIds: number[] = useSelector((state: any) =>
    state.projects.map((project: any) => project.id),
  );

  const getStatusIcon = () => {
    if (item.Status >= 2 && item.Status <= statusIcons.length + 2)
      return statusIcons[item.Status - 2];
  };

  const getUserAvatar = () => {
    if (item.Resources.length === 1) {
      return `${window.location.origin}/versioncontrol/Avatars/${item.Resources[0].ResourceID}/Avatar_64.PNG`;
    } else {
      return ImgUserGroup;
    }
  };

  const isHansoftLink = (): boolean => item.HansoftUrl.split(';').length >= 3;

  const getTaskWebLink = (): string =>
    `/task/${item.HansoftUrl.split(';')[2].replace('Task/', '')}`;

  return (
    <div className={styles.item}>
      <img className={styles.icon} src={getStatusIcon()} alt="" />
      {projectIds.includes(item.ProjectID) && isHansoftLink() ? (
        <Link
          to={getTaskWebLink()}
          className={`${sharedStyles.overflowEllipsis} ${styles.description}`}
        >
          {item.Desc}
        </Link>
      ) : (
        <span
          className={`${sharedStyles.overflowEllipsis} ${styles.descriptionNoAccess}`}
        >
          {item.Desc}
        </span>
      )}
      {item.Resources.length > 0 && (
        <img
          className={styles.resourcesIcon}
          src={getUserAvatar()}
          onError={(event) => {
            event.currentTarget.src = ImgUserGreen;
          }}
          alt=""
        />
      )}
    </div>
  );
});

export default Item;
