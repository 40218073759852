export const getBrowserName = (): string => {
  const navUserAgent = navigator.userAgent;
  let browserName = navigator.appName;

  let tempNameOffset, tempVersionOffset;

  if (navUserAgent.indexOf('Version') !== -1) {
    browserName = 'Opera';
  } else if ((tempVersionOffset = navUserAgent.indexOf('MSIE')) !== -1) {
    browserName = 'Microsoft IE';
  } else if ((tempVersionOffset = navUserAgent.indexOf('Chrome')) !== -1) {
    browserName = 'Chrome';
  } else if ((tempVersionOffset = navUserAgent.indexOf('Safari')) !== -1) {
    browserName = 'Safari';
  } else if ((tempVersionOffset = navUserAgent.indexOf('Firefox')) !== -1) {
    browserName = 'Firefox';
  } else if (
    (tempNameOffset = navUserAgent.lastIndexOf(' ') + 1) <
    (tempVersionOffset = navUserAgent.lastIndexOf('/'))
  ) {
    browserName = navUserAgent.substring(tempNameOffset, tempVersionOffset);
  }

  return browserName;
};
