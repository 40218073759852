import { FC, useCallback, useEffect, useState } from 'react';
import { Task } from '../../../interfaces';
import { formatWork } from '../../../util/date-time/hours_field_functions';
import { Input } from '../Input';
import { setField } from '../../../DDPJS/DDPJS';
import sharedStyles from '../../../shared.module.scss';
import styles from './TimeSpentListField.module.scss';
import { checkIfFieldIsReadOnly, getFieldDefinition } from '../helpers';
import { useIntl } from 'react-intl';
import { getTaskProperty } from '../../../util/task/propertyHelpers';

interface IProps {
  fieldID: string;
  task: Task;
}

export const TimeSpentListField: FC<IProps> = ({ fieldID, task }: IProps) => {
  const [timeSpent, setTimeSpent] = useState<any>(
    getTaskProperty(task, fieldID),
  );
  const [inEditMode, setInEditMode] = useState<boolean>(false);

  const intl = useIntl();

  useEffect(() => {
    if (timeSpent !== getTaskProperty(task, fieldID)) {
      setTimeSpent(getTaskProperty(task, fieldID));
    }
    //eslint-disable-next-line
  }, [getTaskProperty(task, fieldID)]);

  const disableEditMode = useCallback(() => {
    if (inEditMode) {
      setInEditMode(false);
    }
  }, [inEditMode]);

  const enableEditMode = useCallback(() => {
    if (!inEditMode && !checkIfFieldIsReadOnly(fieldID, task)) {
      setInEditMode(true);
    }
  }, [fieldID, task, inEditMode]);

  const onTimeAdded = useCallback(
    (timeToBeAdded: number) => {
      if (!isNaN(timeToBeAdded)) {
        let newTimeSpent = timeSpent || 0;
        newTimeSpent += timeToBeAdded;

        if (newTimeSpent < 0) newTimeSpent = 0;

        setTimeSpent(newTimeSpent);

        if (newTimeSpent !== timeSpent)
          setField(
            getFieldDefinition(fieldID, task).id,
            task.$ID,
            newTimeSpent,
          );

        disableEditMode();
      }
    },
    [fieldID, task, timeSpent, disableEditMode],
  );

  return (
    <>
      {getFieldDefinition(fieldID, task) && (
        <>
          {!inEditMode ? (
            <div
              id="time-spent-read-only"
              className={`${styles.timeSpentReadOnly} ${sharedStyles.displayFlex}`}
              onClick={enableEditMode}
            >
              {formatWork(timeSpent) ||
                `(${intl.formatMessage({
                  id: 'GENERAL.notSet',
                  defaultMessage: 'Not set',
                })})`}
            </div>
          ) : (
            <div className={`${sharedStyles.displayFlex} ${styles.field}`}>
              <span className={styles.addTimeText}>Add time: </span>
              <Input
                value=""
                onChange={onTimeAdded}
                decimal
                number
                selectOnFocus
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default TimeSpentListField;
