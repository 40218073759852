import { TodoListIgnoredFields, ToDoListColumns } from '../../enums';
import { getTimeSpentColumnsInTodo } from './getTimeSpentColumnsInTodo';
import { stringConstants } from '../../constants/strings.constants';
import { EnabledField } from '../../interfaces';
import { TodoListProps } from '../../components/ToDoListPage/ToDoList/ToDoList';
import camelCase from 'lodash/camelCase';

export const filterEnabledTodoListColumns = (
  props: TodoListProps,
): EnabledField[] => {
  const { singleLineFields, shared, intl, todos, projects } = props;
  const fieldIds: string[] = Object.values(ToDoListColumns);

  const timeSpentColumns = getTimeSpentColumnsInTodo(projects, todos);

  if (timeSpentColumns) {
    fieldIds.push(stringConstants.TIME_SPENT_COLUMN);
  }

  const uniqueFields: any = {};

  singleLineFields.forEach((field) => {
    if (fieldIds.indexOf(field.id) >= 0) {
      uniqueFields[field.id] = field;
    }
  });

  let enabledFields: EnabledField[] = [];

  const ignoredFields = Object.values(TodoListIgnoredFields);

  if (fieldIds.indexOf(ToDoListColumns.DUE_DATE) !== -1) {
    enabledFields.push({
      id: ToDoListColumns.DUE_DATE,
      name: intl.formatMessage({ id: 'TODO_LIST.dueDate' }),
      readOnly: true,
    });
  }

  if (fieldIds.indexOf(ToDoListColumns.PRIORITY) !== -1) {
    enabledFields.push({
      id: ToDoListColumns.PRIORITY,
      name: intl.formatMessage({ id: 'TODO_LIST.priority' }),
    });
  }

  Object.keys(uniqueFields).forEach((uniqueField) => {
    const field = uniqueFields[uniqueField];
    const values: any = {};

    if (
      ignoredFields.indexOf(field.id) === -1 &&
      fieldIds.indexOf(field.id) !== -1
    ) {
      if (field.Enum) {
        Object.keys(field.Enum).forEach((uniqueFieldEnum) => {
          const fieldValue = field.Enum[uniqueFieldEnum];
          values[fieldValue[0]] = {
            icon: field.id.indexOf('CC_') === 0 ? fieldValue[2] : fieldValue[0],
            text: fieldValue[1],
            sortOrder: uniqueFieldEnum,
          };
        });
      }

      enabledFields.push({
        id: uniqueField,
        type: field.Type,
        name: intl.formatMessage({
          id: `TODO_LIST_FIELDS.${uniqueField}`,
        }),
        values: Object.keys(values).length >= 1 ? values : null,
        readOnly: field.ReadOnly,
      } as EnabledField);
    }
  });

  enabledFields = enabledFields.sort(
    (fieldA: EnabledField, fieldB: EnabledField) => {
      const fieldAIndex = fieldIds.indexOf(fieldA.id);
      const fieldBIndex = fieldIds.indexOf(fieldB.id);

      if (fieldAIndex < fieldBIndex) return -1;
      else if (fieldAIndex > fieldBIndex) return 1;
      return 0;
    },
  );

  if (shared.integration.enabled) {
    enabledFields.splice(1, 0, {
      id: 'Integration',
      name: shared.integration.data.columnName,
    });
  }

  if (fieldIds.indexOf(stringConstants.TIME_SPENT_COLUMN) !== -1) {
    timeSpentColumns?.forEach((column) => {
      const timeSpentField = singleLineFields.find(
        (field) => field.id === column,
      );
      enabledFields.push({
        id: column,
        name: intl.formatMessage({
          id: `TODO_LIST.${camelCase(timeSpentField?.DisplayName)}`,
          defaultMessage: timeSpentField?.DisplayName,
        }),
        type: 'Hours',
        readOnly: false,
      });
    });
  }

  return enabledFields;
};
