import {
  Column,
  Row,
  useFlexLayout,
  useResizeColumns,
  useTable,
  useSortBy,
  usePagination,
} from 'react-table';
import { FC, memo, Fragment, ReactElement } from 'react';
import styles from './Table.module.scss';
import { Icon } from 'semantic-ui-react';
import { Pagination } from './Pagination';

interface IProps {
  columns: Column[];
  data: any[];
  groups?: any[];
  initialState?: any;
  enablePagination?: boolean;
}

export const Table: FC<IProps> = memo(
  ({ columns, data, groups, initialState, enablePagination }) => {
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      page,
      pageCount,
      gotoPage,
      setPageSize,
      state: { pageIndex, pageSize },
    } = useTable(
      {
        columns,
        data,
        initialState,
      },
      useResizeColumns,
      useFlexLayout,
      useSortBy,
      usePagination,
    );

    const renderTableBody = (
      rows: Row[],
      headerGroups: any[],
    ): ReactElement[] => {
      return rows.map((row, rowIndex) => {
        prepareRow(row);
        return (
          <tr {...row.getRowProps()} key={rowIndex}>
            {row.cells.map((cell, cellIndex) => (
              <td {...cell.getCellProps()} key={cellIndex}>
                {cell.render('Cell')}
                <div
                  className={styles.resizer}
                  {...headerGroups[cellIndex].getResizerProps()}
                />
              </td>
            ))}
          </tr>
        );
      });
    };

    return (
      <>
        <div className="table">
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup, rowIndex) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={rowIndex}>
                  {headerGroup.headers.map((column, columnIndex) => (
                    <th {...column.getHeaderProps()} key={columnIndex}>
                      <span
                        {...column.getHeaderProps(
                          column.getSortByToggleProps(),
                        )}
                      >
                        {column.render('Header')}
                      </span>
                      <span>
                        {column.isSorted &&
                          (column.isSortedDesc ? (
                            <Icon name="angle down" />
                          ) : (
                            <Icon name="angle up" />
                          ))}
                      </span>
                      <div
                        className={styles.resizer}
                        {...column.getResizerProps()}
                      />
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {groups ? (
                groups.map(
                  (group, index) =>
                    group.tasks.length !== 0 && (
                      <Fragment key={index}>
                        {rows.length === 0 ||
                          (rows.filter((row) =>
                            group.tasks.includes(row.original),
                          ).length > 0 && (
                            <tr>
                              <td className="group is-disabled">
                                {group.name}
                              </td>
                            </tr>
                          ))}
                        {renderTableBody(
                          enablePagination
                            ? page.filter((row) =>
                                group.tasks.includes(row.original),
                              )
                            : rows.filter((row) =>
                                group.tasks.includes(row.original),
                              ),
                          headerGroups.map(
                            (headerGroups) => headerGroups.headers,
                          )[0],
                        )}
                      </Fragment>
                    ),
                )
              ) : (
                <>
                  {renderTableBody(
                    enablePagination ? page : rows,
                    headerGroups.map((headerGroups) => headerGroups.headers)[0],
                  )}
                </>
              )}
            </tbody>
          </table>
        </div>
        {enablePagination && pageCount > 1 && (
          <Pagination
            pageIndex={pageIndex}
            pageSize={pageSize}
            setPageSize={setPageSize}
            gotoPage={gotoPage}
            pageCount={pageCount}
          />
        )}
      </>
    );
  },
);
