import { FC, memo, useCallback, useRef, useState } from 'react';
import { LinkedToItem } from '../../../interfaces';
import { ItemFieldWrapper } from '../../ItemDetailsPage/ItemFieldWrapper';
import { Popup } from 'semantic-ui-react';
import { Item } from './Item';

interface IProps {
  linkedToItems: LinkedToItem[];
  displayName: string;
}

export const LinkedTo: FC<IProps> = memo(({ linkedToItems, displayName }) => {
  const [detailsToggle, setDetailsToggle] = useState(false);
  const popupRef = useRef(null);

  const toggleDetailsPopup = useCallback(() => {
    setDetailsToggle((previousDetailsToggle) => !previousDetailsToggle);
  }, [setDetailsToggle]);

  return (
    <ItemFieldWrapper fieldName={displayName}>
      <div className="singleselect singleselect-container" ref={popupRef}>
        <div
          className="singleselect-content js-singleselect-content"
          onClick={toggleDetailsPopup}
        >
          <span>{linkedToItems.length + ' items'}</span>
          <Popup
            open={detailsToggle}
            content={
              <div>
                {linkedToItems.map((linkedToItem, index) => (
                  <Item item={linkedToItem} key={index} />
                ))}
              </div>
            }
            position="bottom left"
            context={popupRef}
            onClose={toggleDetailsPopup}
          />
        </div>
      </div>
    </ItemFieldWrapper>
  );
});

export default LinkedTo;
