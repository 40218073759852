import { Component, createRef, ReactNode } from 'react';
import { Popup } from 'semantic-ui-react';

import { ImgArrowDown, ImgArrowDownWhite } from '../../../images/images';
import FloatingSelection from '../../Fields/FloatingSelection/FloatingSelection';
import styles from './Header.module.scss';
import { getLoggedInResourceID } from '../../../DDPJS/DDPJS';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Dashboard } from '../../../interfaces';

interface IProps extends WrappedComponentProps {
  dashboards: Dashboard[];
  databaseGUIDHash: string;
  pageId: string;
  theme: string;
  selectedDashboardId: number;
  onSelectDashboard(pageId: string): void;
}

interface IState {
  dashboardMenuOpen: boolean;
}
export class Header extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      dashboardMenuOpen: false,
    };
  }
  private dashboardHeaderRef = createRef<HTMLDivElement>();

  private onSelectionChanged = (newValue: string): void => {
    this.props.onSelectDashboard(newValue);
    this.toggleDashboardMenu(false);
  };

  private toggleDashboardMenu = (open: boolean): void => {
    this.setState({
      dashboardMenuOpen: open,
    });
  };

  private getOptions = (dashboards: any[]) => {
    const loggedInUserId = getLoggedInResourceID();
    const { intl } = this.props;

    if (!dashboards || dashboards.length === 0) return [];

    const options = dashboards.map((dashboard) => {
      return {
        value: dashboard.id,
        text:
          dashboard.name.length >= 1
            ? dashboard.name
            : `(${intl.formatMessage({ id: 'GENERAL.noName' })})`,
        subText:
          dashboard.createdBy && dashboard.createdByUserId !== loggedInUserId
            ? dashboard.createdBy
            : null,
      };
    });

    options.sort(function (dashboardA, dashboardB) {
      return dashboardA.text.localeCompare(dashboardB.text, undefined, {
        caseFirst: 'upper',
      });
    });

    return options;
  };

  public render(): ReactNode {
    const { intl, dashboards, theme, selectedDashboardId } = this.props;

    const loggedInUserId = getLoggedInResourceID();
    const selectedDashboard = dashboards.find(
      (dashboard) => dashboard.id === selectedDashboardId,
    );

    let options: any[] = this.getOptions(
      dashboards.filter(
        (dashboard) => dashboard.createdByUserId === loggedInUserId,
      ),
    );
    const sharedDashboards = this.getOptions(
      dashboards.filter(
        (dashboard) => dashboard.createdByUserId !== loggedInUserId,
      ),
    );

    if (options.length >= 1) {
      if (sharedDashboards.length >= 1) {
        options.push({
          seperator: true,
        });

        options = options.concat(sharedDashboards);
      }
    } else {
      options = sharedDashboards;
    }

    return (
      <div className={` ${styles.dashboardHeader} js-dashboard-header`}>
        <div
          className={` ${styles.title} js-title`}
          onClick={() => this.toggleDashboardMenu(true)}
          ref={this.dashboardHeaderRef}
        >
          <div className="text">
            {selectedDashboard
              ? selectedDashboard.name.length >= 1
                ? selectedDashboard.name
                : `(${intl.formatMessage({ id: 'GENERAL.noName' })})`
              : `${intl.formatMessage({
                  id: 'DASHBOARDS.pleaseSelectADashboard',
                })}...`}
          </div>
          <img
            src={theme === 'dark' ? ImgArrowDownWhite : ImgArrowDown}
            alt=""
          />
        </div>
        <div className={styles.spacer} />
        <Popup
          open={this.state.dashboardMenuOpen}
          content={
            <FloatingSelection
              options={options}
              onSelectionChanged={this.onSelectionChanged}
              onClose={() => this.toggleDashboardMenu(false)}
            />
          }
          context={this.dashboardHeaderRef}
          onClose={() => this.toggleDashboardMenu(false)}
          position="bottom right"
        />
      </div>
    );
  }
}

export default injectIntl(Header);
